import React, { useEffect, useState } from 'react'
import Carousels from '../../components/carousel'
import Layout from '../../components/layout'
import Pager from '../../components/pager'
import Seo from '../../components/seo'
// import pictureFeedNews from '../../images/notices.jpeg'
import { graphql, Link, navigate } from 'gatsby'
import logo from '../../images/newspaper.png'
import { useForm } from '../../hooks/useForm'
import urlSlug from 'url-slug'
import { findNoveltieCategoryExist } from '../../helpers/findNoveltieCategoryExist'
import { NEWS_GENERAL_ROUTE } from '../../utils/variables/generalRoutes'
import moment from 'moment'
import { validateNews } from '../../helpers/validateNews'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'

const newListOuAll = ({ data, pageContext }) => {
  const { noveltiesCategoriesData } = pageContext

  const {
    allStrapiNovelties: news,
    strapiOrganizationalUnit: organizationalUnit
  } = data

  const objCarousel = {
    visible: true,
    slide: organizationalUnit.mainCover?.noveltiesCover
  }

  const [valuesFilter, handleInputChange] = useForm({
    categorySelected: 'Todas'
  })
  const { categorySelected } = valuesFilter

  const [invalidCategory, setInvalidCategory] = useState(false)

  useEffect(() => {
    if (categorySelected !== 'Todas') {
      setInvalidCategory(() =>
        findNoveltieCategoryExist(categorySelected, news.nodes)
      )
      if (!invalidCategory !== true) {
        navigate(`${urlSlug(categorySelected)}/`, {
          replace: false
        })
      }
    }
  }, [categorySelected, invalidCategory])

  const handleClickViewAllUcc = () => {
    navigate(`/${NEWS_GENERAL_ROUTE}/`, {
      replace: false
    })
  }

  return data ? (
    <Layout page={organizationalUnit} logo={organizationalUnit.logo}>
      <Seo title={`Noticias ${organizationalUnit.name || ''}`} />
      <section className="schedule-feed mb-3">
        {objCarousel?.slide && objCarousel?.slide.length > 0 && (
          <div className="d-flex flex-column schedule-header-carousel">
            <Carousels carousel={objCarousel} keyName={'newsFeed-'} />
          </div>
        )}
        <div className="container">
          <div className="my-5">
            <div className="row d-flex  mb-3">
              <div className=" col-12 col-md-8">
                <div className=" border-bottom">
                  <h5 className="text-uppercase title-secondary">
                    <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
                    Noticias{' '}
                    <span className="font-weight-bold text-main pb-1">
                      {organizationalUnit.name}
                    </span>
                  </h5>
                </div>
              </div>
              <form className="  col-12 col-md-4 mt-separate">
                <div className="d-flex">
                  <select
                    className="form-control form-control-sm"
                    onChange={handleInputChange}
                    name="categorySelected"
                    defaultValue="-1"
                    disabled={validateNews(news.nodes)}
                  >
                    <option value="-1" disabled>
                      Por Categoría
                    </option>
                    <option value="Todas">Todas</option>
                    {noveltiesCategoriesData.map((category) => (
                      <option value={category.name} key={category.strapiId}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
              <div className="col-12">
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn btn-sm ucc-background-color border-ucc-boton "
                    onClick={handleClickViewAllUcc}
                  >
                    Todas las noticias UCC
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              {news.nodes?.length === 0 ? (
                <h4 className="mt-5 col">
                  No se encontraron noticias pertenecientes a{' '}
                  {`"${organizationalUnit.name}"`}
                </h4>
              ) : (!invalidCategory !== false &&
                  categorySelected !== 'Todas') ||
                validateNews(news.nodes) ? (
                <h4 className="mt-5 col">
                  {' '}
                  No se encontraron noticias de {`"${organizationalUnit.name}"`}{' '}
                  con la categoría
                  {` "${categorySelected}"`}
                </h4>
              ) : (
                news.nodes.map((item) => {
                  if (
                    item.dateTo === null ||
                    moment(item.dateTo, 'YYYY/MM/DD').isSameOrAfter(moment())
                  )
                    return (
                      <div
                        className={`mb-4 col-12 col-md-4 'hightlighted'
                   `}
                        key={`news-ou-all-NodesId-${item.strapiId}`}
                      >
                        <div className="h-100 d-flex flex-column rounded shadow-sm news-card overflow-hidden position-relative">
                          <div
                            className="news-image"
                            style={{
                              backgroundImage: `url(

                            ${item.thumbnail ? item.thumbnail.url : logo} 
                       
                        )`
                            }}
                          ></div>
                          <div className="flex-grow-1 p-4 d-flex flex-column news-info">
                            <h6>
                              <small>
                                {moment(item.date)
                                  .locale('es')
                                  .format('DD-MM-YYYY')}
                              </small>
                            </h6>

                            {/* Etiqueta de la noticia - novelties categories */}
                            {/* <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">                            
                              {item.novelties_categories?.map((noveltieCategory, i) => {
                                if ((item.novelties_categories?.length - 1) > i) {
                                  return noveltieCategory?.name + " - "
                                } else {
                                  return noveltieCategory?.name
                                }
                              })}
                            </div> */}
                            
                            <h5 className="font-weight-bold">{item.title}</h5>
                            <div className='noticies-markdown markdown-format'>
                              <p>{item.summary}</p>
                            </div>

                            <Link
                              to={`/${NEWS_GENERAL_ROUTE}/${item.slug}`}
                              className={`btn btn-outline-info mt-auto fit-content text-uppercase 
                                rounded-pill px-4 font-secondary eventuality-button-font-size
                              }`}
                            >
                              VER MÁS
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                })
              )}
            </div>
            <Pager pageContext={pageContext} />
          </div>
        </div>
      </section>
    </Layout>
  ) : null
}

export default newListOuAll

export const newListQueryOU = graphql`
  query($skip: Int, $limit: Int, $date: Date, $orgUnitId: Int) {
    allStrapiNovelties(
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
      filter: {
        date: { lte: $date }
        organizational_units: { elemMatch: { id: { eq: $orgUnitId } } }
      }
    ) {
      nodes {
        strapiId
        title
        slug
        summary
        date
        dateTo
        thumbnail {
          url
        }
        novelties_categories {
          name
        }
      }
    }
    strapiOrganizationalUnit(strapiId: { eq: $orgUnitId }) {
      name
      logo {
        url
        image {
          url
        }
      }
      navbar {
        name
        icon {
          code
          type
        }
        careersList {
          name
          ucc_careers {
            name
            slug
          }
        }
        organizationalUnit {
          name
          icon {
            code
            type
          }
          organizational_unit {
            name
            slug
          }
        }
        organizational_unit {
          name
          slug
        }
        page {
          name
          slug
        }
        pages {
          name
          icon {
            code
            type
          }
          page {
            name
            slug
          }
        }
      }
      mainCover {
        noveltiesCover {
          url
          visible
          name
          imageDescription
          content
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
    }
  }
`
